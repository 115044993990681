<template>
  <master-detail
    :actionBarButtons="actionBarButtons"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    descriptionProperty="projetoId"
    formTitle="Cadastro Tipo RH"
    :formWidth="700"
    :hasNewButton="false"
    :opts="{}"
    :resourceUrl="resourceUrl"
    :serverPagination="false"
  ></master-detail>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(['clientId']),
    cols: function () {
      const tiporh = [
        { id: 1, nome: "Direto" },
        { id: 2, nome: "Indireto" },
      ];

      return [
        {
          key: "funcionarioId",
          name: "ID",
        },
        {
          key: "matricula",
          name: "Matrícula",
        },
        {
          key: "nome",
          name: "Funcionário",
        },
        {
          key: "formacaoli_nome",
          name: "Formação",
        },
        {
          key: "escolaridadeli_nome",
          name: "Escolaridade",
        },
        {
          key: "titulo",
          name: "Projeto",
        },
        {
          key: "tiporh",
          name: "Tipo RH",          
          rel: { toEdit: tiporh, key: "id", name: "nome" },
        },
      ];
    },
    resourceUrl: function () {
      return `/v1/rh/${this.clientId}/tiporh`;
    },
  },
};
</script>
